import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import HomePage from './pages/userNamePage';
import AppSpace from './pages/appSpacePage';
import AboutPage from './pages/userPasswordPage';
import RequestPasswordPage from './pages/requestPasswordPage';
import RestorePassword from './pages/restorePasswordPage';
import Unsubscribe from './pages/unsubscribePage';
import { connect } from './../Store';
import './App.css';
import './pageTransitions/slideTransition.css';

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevDepth: this.getPathDepth(this.props.location),
      outsideRedirectUrl: undefined,
    };
  }

  componentDidMount() {
    console.log('ok');

    const { location, userType, history } = this.props;

    // Capture the redirect URL from query parameters
    const queryParams = new URLSearchParams(location.search);
    const redirectUrl = queryParams.get('redirectUrl');

    this.setState({ outsideRedirectUrl: redirectUrl });

    if (userType === 'employee') {
      history.push('/app-space');
    }
  }

  getPathDepth(location) {
    let pathArr = location.pathname.split('/');
    pathArr = pathArr.filter(n => n !== '');
    return pathArr.length;
  }

  render() {
    const { location, userType } = this.props;
    const { outsideRedirectUrl } = this.state;
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 1200, exit: 0 };

    return (
      <TransitionGroup component="div" className="App">
        <CSSTransition
          key={currentKey}
          timeout={timeout}
          classNames="pageSlider"
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <div
            className={
              this.getPathDepth(location) - this.state.prevDepth >= 0
                ? 'left'
                : 'right'
            }
          >
            <Switch location={location}>
              {userType === null && (
                <Route
                  path="/name"
                  exact
                  render={props => (
                    <HomePage {...props} redirectUrl={outsideRedirectUrl} />
                  )}
                />
              )}

              <Route path="/password" exact component={AboutPage} />

              <Route
                path="/password/restore"
                exact
                component={RequestPasswordPage}
              />

              {userType === 'employee' && (
                <Route path="/app-space" exact component={AppSpace} />
              )}
              <Route path="/setPassword/:token" component={RestorePassword} />
              <Route path="/unsubscribe" component={Unsubscribe} />
              <Redirect to="/name" />
            </Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

Routes.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userName: PropTypes.string,
  userType: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

Routes.defaultProps = {
  userName: null,
  userType: null,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default withRouter(connect(mapStateToProps)(Routes));
