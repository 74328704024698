import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import cookie from 'react-cookies';
import Img from 'react-image';
import { withTranslation } from 'react-i18next';
import { Icon } from 'crm-ui/lib';
import { connect, actions } from '../../Store';
import API from '../../API';

const AppLabel = styled.a`
  width: ${rem(230)};
  height: ${rem(230)};
  background: ${props => (props.disabled ? '#CBCBCB' : props.color)};
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  text-decoration: none;
  :hover {
    box-shadow: ${props =>
      props.disabled ? 'none' : '0 3px 5px 0 rgba(0, 0, 0, 0.16)'};
  }
`;

const IconWrapper = styled.div`
  width: ${rem(116)};
  height: ${rem(116)};
`;

const AppName = styled.div`
  display: flex;
  font-size: ${rem(44)};
  font-weight: bold;
  color: white;
  text-decoration: none;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  gap: ${rem(6)};
  height: ${props => rem(props.width)};
  ${({ theme }) => theme.media.m`
  width: ${props => rem(props.width)};
  height: 288px;
  justify-content: space-between;
  flex-direction: row;
`};
`;

const UserInfoWrapper = styled.div`
  position: absolute;
  right: ${rem(20)};
  top: ${rem(20)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${rem(8)};
  visibility: hidden;
  ${({ theme }) => theme.media.m`
  visibility: visible;
  `};
`;

const UserWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${rem(8)};
  min-width: ${rem(210)};
  align-items: center;
  :hover {
    background: #eeeeef;
  }
  cursor: pointer;
`;

const Photo = styled(Img)`
  height: ${rem(50)};
  border-radius: 50%;
`;

const UserPhotoWrapper = styled.div`
  margin-right: ${rem(20)};
`;
const UserNameWrapper = styled.div``;

const UserInfoPopupWrapper = styled.div`
  margin-top: ${rem(16)};
  background: white;
  border-radius: ${rem(8)};
  padding: ${rem(24)} ${rem(8)} ${rem(24)} ${rem(8)};
`;
const UserInfoItem = styled.div`
  height: ${rem(32)};
  padding: ${rem(4)};
  border-radius: ${rem(8)};
  display: flex;
  width: 200px;
  align-items: center;
  :hover {
    background: #eeeeef;
  }
  cursor: pointer;
`;

const UserInfoMenuLabel = styled.a`
  text-decoration: none;
  color: black;
`;

const APPS = [
  {
    id: 'CRM',
    name: 'CRM',
    color: '#1D71B8',
    icon: 'crm_logo',
    url: 'https://crm.oxyrion.com?token=',
    url_local: 'http://localhost:3005/?token=',
  },
  {
    id: 'ADMIN',
    name: 'ADMIN',
    color: '#EE6500',
    icon: 'admin_logo',
    url: 'https://admin.oxyrion.com#token=',
    url_local: 'http://localhost:3003#token=',
  },
  {
    id: 'EOM',
    name: 'EOM',
    color: '#A448A3',
    icon: 'bi_logo',
    url: 'https://eom.oxyrion.com#token=',
    url_local: 'http://localhost:3004#token=',
  },
];

const PORTAL = {
  id: 'PORTAL',
  name: 'PORTAL',
  color: '#ED1D24',
  icon: 'b2b_logo',
  url: 'https://portal.oxyrion.com',
  url_local: 'http://localhost:3001',
};

const cookieConfig = {
  crm: 'oxyrionAccessToken',
  b2b: 'b2bAccessToken',
};

const photoBasePath = `https://api-v2.oxyrion.com/images/500x500`;

class Password extends React.Component {
  state = {
    showUserInfoPopup: false,
    displayUser: false,
    b2bUser: null,
  };

  async componentDidMount() {
    const b2bToken = this.getCookie('b2b');
    const { user: crmUser } = this.props;

    const oxyrionToken = this.getCookie('crm');

    this.setState({
      oxyrionToken,
    });

    setTimeout(() => {
      this.setState({
        displayUser: true,
      });
    }, 1500);

    if (b2bToken && crmUser.isB2BUser) {
      try {
        API.setToken(b2bToken);
        const b2bUser = await API.b2bMeAction();

        this.setState({ b2bUser });
      } catch (e) {
        this.setState({ b2bUser: null });
      }
    }
  }

  getCookie(name) {
    return cookie.load(cookieConfig[name]);
  }

  handleLogOut() {
    actions.setLoggingOut(true);
    actions.loggout();
  }

  render() {
    const {
      showUserInfoPopup,
      displayUser,
      b2bUser,
      oxyrionToken,
    } = this.state;

    const {
      user,
      user: { apps_permisions: apps },
    } = this.props;

    const appsLocal = [...APPS];

    const actualPhotoUrl =
      user.profile_picture && user.profile_picture.fullPath;

    return (
      <Wrapper
        width={
          appsLocal.length * 240 +
          (user.principal_name === 'skhrimi@dejmark.com' ? 240 : 0) +
          (user.isB2BUser ? 240 : 0)
        }
        className="page"
      >
        {displayUser && (
          <UserInfoWrapper>
            <UserWrapper
              onClick={() =>
                this.setState({
                  showUserInfoPopup: true,
                })
              }
            >
              <UserPhotoWrapper>
                <Photo
                  src={[
                    `${photoBasePath}/${actualPhotoUrl}`,
                    '/images/icons/avatar.svg',
                  ]}
                />
              </UserPhotoWrapper>
              <UserNameWrapper>{user.display_name}</UserNameWrapper>
            </UserWrapper>

            {showUserInfoPopup && (
              <UserInfoPopupWrapper
                onMouseLeave={() =>
                  this.setState({
                    showUserInfoPopup: false,
                  })
                }
              >
                <UserInfoItem>
                  <UserInfoMenuLabel
                    href="https://crm.oxyrion.com/SK/settings"
                    target="_blank"
                  >
                    {this.props.t('Nastavenia účtu')}
                  </UserInfoMenuLabel>
                </UserInfoItem>
                <UserInfoItem>
                  <UserInfoMenuLabel onClick={() => this.handleLogOut()}>
                    {this.props.t('Odhlásiť sa')}
                  </UserInfoMenuLabel>
                </UserInfoItem>
              </UserInfoPopupWrapper>
            )}
          </UserInfoWrapper>
        )}

        {appsLocal.map(app => {
          const disabled = apps && apps.indexOf(app.id) === -1;
          return (
            <AppLabel
              color={app.color}
              href={
                !disabled &&
                `${
                  process.env.NODE_ENV !== 'development'
                    ? app.url
                    : app.url_local
                }${oxyrionToken}`
              }
              target="_blank"
              disabled={disabled}
            >
              <IconWrapper>
                <Icon name={app.icon} size="xxl" color="white" />
              </IconWrapper>
              <AppName>{app.name}</AppName>
            </AppLabel>
          );
        })}

        {['skhrimi@dejmark.com', 'skmarma@dejmark.com', 'sklamlu@dejmark.com']
          .includes && (
          <AppLabel
            color="black"
            href={`https://crm-beta.oxyrion.com?token=${oxyrionToken}`}
            target="_blank"
          >
            <IconWrapper>
              <Icon name="crm_logo" size="xxl" color="white" />
            </IconWrapper>
            <AppName>CRM beta</AppName>
          </AppLabel>
        )}

        {user.isB2BUser && (
          <AppLabel
            color={PORTAL.color}
            href={b2bUser && PORTAL.url}
            onClick={() => !b2bUser && this.props.history.push('/password')}
            target="_blank"
          >
            <IconWrapper>
              <Icon name={PORTAL.icon} size="xxl" color="white" />
            </IconWrapper>
            <AppName>{PORTAL.name}</AppName>
          </AppLabel>
        )}
      </Wrapper>
    );
  }
}

Password.propTypes = {
  user: PropTypes.shape({
    apps_permisions: PropTypes.arrayOf(PropTypes.string),
    isB2BUser: PropTypes.bool,
    profile_picture: PropTypes.string,
    display_name: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

Password.defaultProps = {
  user: {
    apps: [],
  },
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};
export default withTranslation()(connect(mapStateToProps)(Password));
